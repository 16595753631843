import { getGuessStatuses } from './statuses'
import { GAME_TITLE } from '../constants/strings'
import { Attempt } from '../../../common/types'

export const shareStatus = (
  guesses: Attempt[],
  lost: boolean,
) => {
  navigator.clipboard.writeText(getShareStatus(guesses, lost));
}

export const getShareStatus = ( guesses: Attempt[], lost: boolean) => {
  return `${GAME_TITLE} ${lost ? 'X' : guesses.length}/6\n\n` + generateEmojiGrid(guesses);
};

export const generateEmojiGrid = (guesses: Attempt[]) => {
  return guesses
    .map((guess) => {
      const status = getGuessStatuses(guess);
      return guess.word.toUpperCase()
        .split('')
        .map((_, i) => {
          switch (status[i]) {
            case 'correct':
              return '🟩'
            case 'present':
              return '🟨'
            default:
              return '⬜'
          }
        })
        .join('')
    })
    .join('\n')
}
