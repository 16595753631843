import { Attempt } from '../../../common/types'
//import { solution } from './words'

export type CharStatus = 'absent' | 'present' | 'correct'

export const getStatuses = (
  guesses: Attempt[]
): { [key: string]: CharStatus } => {
  const charObj: { [key: string]: CharStatus } = {}

  guesses.forEach((att) => {
    att.word.toUpperCase().split('').forEach((letter, i) => {
      if (att.greens.includes(i)) {
        //make status correct
        return (charObj[letter] = 'correct')
      }
      if (att.yellows.includes(i)) {
        //make status correct
        return (charObj[letter] = 'present')
      }
      return (charObj[letter] = 'absent');

      /*
      if (!solution.includes(letter)) {
        // make status absent
        return (charObj[letter] = 'absent')
      }

      

      if (charObj[letter] !== 'correct') {
        //make status present
        return (charObj[letter] = 'present')
      }*/
    })
  })

  return charObj;
}

export const getGuessStatuses = (guess: Attempt): CharStatus[] => {
  //const splitSolution = solution.split('')
  //const splitGuess = guess.word.toUpperCase().split('');

  //const solutionCharsTaken = splitSolution.map((_) => false)

  const statuses: CharStatus[] = Array.from(Array(guess.word.length));

  for (let i = 0; i < guess.word.length; ++i) {
    if (guess.greens.includes(i)) {
      statuses[i] = 'correct';
    } else if (guess.yellows.includes(i)) {
      statuses[i] = 'present';
    } else {
      statuses[i] = 'absent';
    }
  }

  /*
  // handle all correct cases first
  splitGuess.forEach((letter, i) => {
    if (letter === splitSolution[i]) {
      statuses[i] = 'correct'
      solutionCharsTaken[i] = true
      return
    }
  })

  splitGuess.forEach((letter, i) => {
    if (statuses[i]) return

    if (!splitSolution.includes(letter)) {
      // handles the absent case
      statuses[i] = 'absent'
      return
    }

    // now we are left with "present"s
    const indexOfPresentChar = splitSolution.findIndex(
      (x, index) => x === letter && !solutionCharsTaken[index]
    )

    if (indexOfPresentChar > -1) {
      statuses[i] = 'present'
      solutionCharsTaken[indexOfPresentChar] = true
      return
    } else {
      statuses[i] = 'absent'
      return
    }
  })*/

  return statuses;
};
