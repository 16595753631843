import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Как играть" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Угадай слово с 6 попыток. После каждого предположения цвет плитки будет измените, чтобы показать, насколько ваша догадка была близка к слову.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="П" />
        <Cell value="Е" status="correct"/>
        <Cell value="Н" />
        <Cell value="И" />
        <Cell value="С" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Буква Е в слове и на правильном месте.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="Н" />
        <Cell value="О" />
        <Cell value="Р" status="present" />
        <Cell value="К" />
        <Cell value="А" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Буква Р в слове есть, но не на том месте.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="Л" />
        <Cell value="О" />
        <Cell value="Б" />
        <Cell value="О" />
        <Cell value="К" status="absent" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Буквы К нет в слове ни в одном месте.
      </p>
    </BaseModal>
  )
}
