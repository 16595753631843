import {
  InformationCircleIcon,
  RefreshIcon,
} from '@heroicons/react/outline'
import { useState, useEffect } from 'react'
import { Alert } from './components/alerts/Alert'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { AboutModal } from './components/modals/AboutModal'
import { InfoModal } from './components/modals/InfoModal'
import { StatsModal } from './components/modals/StatsModal'
import {
  GAME_TITLE,
  WIN_MESSAGES,
  ABOUT_GAME_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  WORD_NOT_FOUND_MESSAGE,
} from './constants/strings'
import {
  MAX_WORD_LENGTH,
  MAX_CHALLENGES,
  ALERT_TIME_MS,
  REVEAL_TIME_MS,
  GAME_LOST_INFO_DELAY,
} from './constants/settings'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
} from './lib/localStorage'

import './App.css'

import axios from "axios";

import { /*State,*/ Attempt, State } from '../../common/types';

function App() {

  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)
  const [isNotEnoughLetters, setIsNotEnoughLetters] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isWordNotFoundAlertOpen, setIsWordNotFoundAlertOpen] = useState(false)
  const [currentRowClass, setCurrentRowClass] = useState('')
  const [isGameLost, setIsGameLost] = useState(false)

  const [answer, setAnswer] = useState<string|null>(null);

  const [successAlert, setSuccessAlert] = useState('')
  const [isRevealing, setIsRevealing] = useState(false);

  const [guesses, setGuesses] = useState<Attempt[]>(() => {
    const loaded = loadGameStateFromLocalStorage()
    setAnswer(loaded?.answer || null);
    if (loaded?.answer !== answer) {
      return []
    }
    const gameWasWon = loaded.answer && loaded.guesses.map((att) => att.word).includes(loaded.answer);
    if (gameWasWon) {
      setIsGameWon(true);
    }
    if (loaded.guesses.length === MAX_CHALLENGES && !gameWasWon) {
      setIsGameLost(true);
    }
    return loaded.guesses;
  })

  const [stats, setStats] = useState(() => loadStats())

  useEffect(() => {
    saveGameStateToLocalStorage({ guesses, answer })
  }, [guesses, answer])

  useEffect(() => {
    if (isGameWon) {
      setTimeout(() => {
        setSuccessAlert(
          WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
        )

        setTimeout(() => {
          setSuccessAlert('')
          setIsStatsModalOpen(true)
        }, ALERT_TIME_MS)
      }, REVEAL_TIME_MS * MAX_WORD_LENGTH)
    }
    if (isGameLost) {
      setTimeout(() => {
        setIsStatsModalOpen(true)
      }, GAME_LOST_INFO_DELAY)
    }
  }, [isGameWon, isGameLost])

  const onChar = (value: string) => {
    if (
      currentGuess.length < MAX_WORD_LENGTH &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1))
  }

  const onEnter = async () => {
    if (isGameWon || isGameLost) {
      return
    }
    if (!(currentGuess.length === MAX_WORD_LENGTH)) {
      setIsNotEnoughLetters(true)
      setCurrentRowClass('jiggle')
      return setTimeout(() => {
        setIsNotEnoughLetters(false)
        setCurrentRowClass('')
      }, ALERT_TIME_MS)
    }

    const result = await axios.post("/tryguess", { guess: currentGuess });
    setGuesses(result.data.newstate.attempts);
    setAnswer(result.data.answer);
    if (result.data.error) {
      if (result.data.error === 'NOTFOUND') {
        setIsWordNotFoundAlertOpen(true)
        setCurrentRowClass('jiggle')
        return setTimeout(() => {
          setIsWordNotFoundAlertOpen(false)
          setCurrentRowClass('')
        }, ALERT_TIME_MS);
      }
    }
    const newState:State = result.data.newstate;
    console.log(result);

    /*if (!isWordInWordList(currentGuess)) {
      setIsWordNotFoundAlertOpen(true)
      setCurrentRowClass('jiggle')
      return setTimeout(() => {
        setIsWordNotFoundAlertOpen(false)
        setCurrentRowClass('')
      }, ALERT_TIME_MS)
    }*/

    setIsRevealing(true)
    // turn this back off after all
    // chars have been revealed
    setTimeout(() => {
      setIsRevealing(false)
    }, REVEAL_TIME_MS * MAX_WORD_LENGTH)

    //const winningWord = result.data.answer !== null;

    if (
      currentGuess.length === MAX_WORD_LENGTH &&
      newState.attempts.length <= MAX_CHALLENGES &&
      !isGameWon
    ) {
      setCurrentGuess('')

      if (newState.attempts.some((attempt) => attempt.greens.length === 5)) {
        setStats(addStatsForCompletedGame(stats, newState.attempts.length))
        return setIsGameWon(true)
      }

      if (newState.attempts.length === MAX_CHALLENGES) {
        setStats(addStatsForCompletedGame(stats, newState.attempts.length + 1))
        setIsGameLost(true)
      }
    }
  }

  const newGame = async () => {
    const result = await axios.post("/newgame");
    setGuesses(result.data.newstate.attempts);
    setCurrentGuess('');
    setAnswer(result.data.answer);

    setIsGameWon(false);
    setIsGameLost(false);
    setIsStatsModalOpen(false);
  }

  return (
    <div className="pt-2 pb-8 max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div className="flex w-80 mx-auto items-center mb-4 mt-4">
        <h1 className="text-xl ml-2.5 grow font-bold dark:text-white">
          {GAME_TITLE}
        </h1>
        <RefreshIcon
          className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white anim-icon"
          onClick={newGame}
        />
        <InformationCircleIcon
          className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white anim-icon"
          onClick={() => setIsInfoModalOpen(true)}
        />
        {
          /*
          <ChartBarIcon
            className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white anim-icon"
            onClick={() => setIsStatsModalOpen(true)}
          />*/
        }
      </div>
      <Grid
        guesses={guesses}
        currentGuess={currentGuess}
        isRevealing={isRevealing}
        currentRowClassName={currentRowClass}
      />
      <Keyboard
        onChar={onChar}
        onDelete={onDelete}
        onEnter={onEnter}
        guesses={guesses}
        isRevealing={isRevealing}
      />
      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />
      <StatsModal
        isOpen={isStatsModalOpen}
        handleClose={() => setIsStatsModalOpen(false)}
        guesses={guesses}
        gameStats={stats}
        isGameLost={isGameLost}
        isGameWon={isGameWon}
        handleShare={() => {
          setSuccessAlert('Текст результатов скопирован в буфер обмена!')
          return setTimeout(() => setSuccessAlert(''), ALERT_TIME_MS)
        }}
        newGame={newGame}
      />
      <AboutModal
        isOpen={isAboutModalOpen}
        handleClose={() => setIsAboutModalOpen(false)}
      />

      <div className="flex w-80 mx-auto items-center">
        <button
          type="button"
          className="mx-auto mt-4 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
          onClick={() => setIsAboutModalOpen(true)}
        >
          {ABOUT_GAME_MESSAGE}
        </button>

        <button
          type="button"
          className="mx-auto mt-4 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
          onClick={() => newGame()}
        >
          {'Начать заново'}
        </button>
      </div>

      <Alert message={NOT_ENOUGH_LETTERS_MESSAGE} isOpen={isNotEnoughLetters} />
      <Alert
        message={WORD_NOT_FOUND_MESSAGE}
        isOpen={isWordNotFoundAlertOpen}
      />
      <Alert
        message={`А слово-то было ${answer?.toUpperCase()}`}
        isOpen={isGameLost && !isRevealing}
      />
      <Alert
        message={successAlert}
        isOpen={successAlert !== ''}
        variant="success"
        topMost={true}
      />
    </div>
  )
}

export default App
