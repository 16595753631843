export const GAME_TITLE = 'Хардл'

export const WIN_MESSAGES = ['Отлично!', 'Получилось!', 'Поздравляем!', 'Ура!']
export const GAME_COPIED_MESSAGE = 'Game copied to clipboard'
export const ABOUT_GAME_MESSAGE = 'Об этой игре'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'В слове не хватает букв!'
export const WORD_NOT_FOUND_MESSAGE = 'В словаре игры нет такого слова, попробуйте другое'
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Must use ${guess} in position ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Guess must contain ${letter}`
export const ENTER_TEXT = 'Ввод'
export const DELETE_TEXT = 'Delete'
export const TOTAL_TRIES_TEXT = 'Сыграно'
export const SUCCESS_RATE_TEXT = 'Побед всего'
export const CURRENT_STREAK_TEXT = 'Побед подряд сейчас'
export const BEST_STREAK_TEXT = 'Подряд максимум'
