import { BaseModal } from './BaseModal'
import { SocialIcon } from 'react-social-icons';

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Об этой игре" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Это не Вордл - это Хардл.<br/> Вордл - это не Хардл. <br/> Вы играете в Вордл. <br/> Но Хардл играет в Вас.<br/>
        
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300 mt-2">
        <a
          href="https://t.me/alcotaran"
          className="underline font-bold"
        >
          Написать автору
        </a>{' '}
      </p>
      <p className="mb-2 mt-4">
        <SocialIcon className="mx-2" url="https://t.me/alcotaran" network="telegram" />
        <SocialIcon className="mx-2" url="https://twitter.com/alcotaran" />
        <SocialIcon className="mx-2" url="https://linkedin.com/pub/alexander-taran/56/564/1a5" />
        <SocialIcon className="mx-2" url="https://www.youtube.com/channel/UCxqFqA22Y4l5gNsSsQ9YpjA" />
        <SocialIcon className="mx-2" url="mailto:alex.y.taran+hardle@gmail.com?subject=Hardle" network="email" />
      </p>
    </BaseModal>
  )
}
